.rich-text-editor {
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;

  // TODO: these styles should align with the markdown component too

  textarea,
  &__preview,
  .public-DraftEditor-content {
    border: none !important;
    min-height: 10em !important;
    max-height: none !important;
    margin: 0 !important;
    padding: 15px !important;
    width: 100%;
  }

  &--scroll {
    textarea,
    .public-DraftEditor-content {
      overflow: auto !important;
      height: 20em !important;
    }
  }

  &--short {
    textarea,
    .public-DraftEditor-content {
      height: 10em !important;
    }
  }

  textarea {
    font-family: Menlo, monospace;
  }

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    top: 15px;
    left: 15px;
    opacity: 0.5;
  }

  &--disabled {
    opacity: 0.2;
  }

  a {
    text-decoration: underline;
  }

  img {
    max-width: 100%;
    display: block;
  }

  figure:after {
    content: '';
    clear: both;
    display: table;
  }

  blockquote {
    position: relative;
    padding-left: 65px;

    &:before {
      content: '‟';
      position: absolute;
      top: -10px;
      left: 0;
      font-size: 120px;
      opacity: 0.2;
      line-height: 1;
    }
  }

  .DraftEditor-root {
    position: relative;

    li {
      // Note: kept in sync with markdown.less
      margin-bottom: 0.3em;
      margin-left: 20px;
    }

    .block {
      &--center {
        text-align: center;
      }

      &--right {
        text-align: right;
      }

      &--justify {
        text-align: justify;
      }
    }

    .atomic {
      &--left img {
        margin-right: 20px;
      }

      &--right img {
        margin-left: 20px;
      }
    }
  }
}
