.markdown-body .linked-heading {
  a {
    color: inherit;
    font-size: 15px;
    margin-left: -1.4em;
    display: inline-block;
    vertical-align: middle;
    opacity: 0;
  }

  a + span {
    vertical-align: middle;
  }

  &:hover a {
    opacity: 1;
    text-decoration: none;
  }
}
