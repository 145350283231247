.rich-text-editor-toolbar {
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 6px 12px;
  user-select: none;
  display: flex;

  .rich-text-editor-divider {
    margin: 0 5px;
  }
}
