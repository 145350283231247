@import '../../../styles/easing';

@shadow: 0 1px 2px rgb(0 0 0 / 20%);
@borderColor: rgb(0 0 0 / 25%);

@fade: opacity 100ms linear;
@scale: transform 100ms @easeInOutBack;
@slide: left 200ms @easeOutCubic;

.rich-text-editor-inline-toolbar {
  position: absolute;
  z-index: 1;
  transform: translate(-50%, -100%) scale(0.5);
  transition: @fade, @scale;
  opacity: 0;
  pointer-events: none;
  transition: @fade, @scale;

  &--active {
    transform: translate(-50%, -120%) scale(1);
    opacity: 1;
    pointer-events: auto;
  }

  &--sliding {
    transition: @fade, @scale, @slide;
  }

  &__stage {
    position: relative;
    box-shadow: @shadow;
    border: 1px solid @borderColor;
    background: #fff;
    border-radius: 2px;
    user-select: none;
    transform-origin: bottom center;
    padding: 2px;
    display: flex;
    align-items: center;

    &:after,
    &:before {
      position: absolute;
      content: '';
      top: 100%;
      left: 50%;
    }

    &:before {
      z-index: -1;
      width: 10px;
      height: 10px;
      box-shadow: 1px 1px 2px rgb(0 0 0 / 35%);
      transform: translate(-50%, -7px) rotate(45deg);
    }

    &:after {
      z-index: 1;
      border-color: #fff transparent transparent;
      border-width: 6px;
      border-style: solid;
      transform: translate(-50%, -1px);
    }
  }
}
