.rich-text-editor-button {
  margin: 1px !important;
  opacity: 0.4 !important;
  color: #000 !important;
  background: inherit !important;
  border-radius: 2px !important;

  &:not(.disabled):hover {
    opacity: 1 !important;
  }

  &--toggled {
    opacity: 1 !important;
  }

  &.disabled {
    opacity: 0.15 !important;
  }
}
